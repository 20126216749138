import React from "react";
import { SEO, PageLayout, DynamicScript } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import "../style/beer-menu.css";

export default class BeerMenuPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <div>
              <div id={"menu-container"} />
              <DynamicScript
                type={"application/javascript"}
                delay={600}
                body={
                  "!function getScript(e,t){var a=document.createElement('script'),n=document.getElementsByTagName('script')[0];a.async=1,n.parentNode.insertBefore(a,n),a.onload=a.onreadystatechange=function(e,n){(n||!a.readyState||/loaded|complete/.test(a.readyState))&&(a.onload=a.onreadystatechange=null,a=undefined,n||t&&t())},a.src=e}('https://embed-menu-preloader.untappdapi.com/embed-menu-preloader.min.js',function(){PreloadEmbedMenu('menu-container',17548,66017)});"
                }
              />
            </div>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Beer Menu" }) {
      title
    }
  }
`;
